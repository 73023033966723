<template>
  <v-container style="margin-bottom: 100px">
    <v-row class="sc-title mt-7">
      <v-col md="8" sm="6">
        <div>
          <h1>TODAY PROJECTS</h1>
        </div>
      </v-col>
      <v-col md="4" sm="6">
        <div class="text-right">
          <v-btn color="primary" @click="$router.push({ name: 'Evaluation' })"
            >Back</v-btn
          >
        </div>
      </v-col>
    </v-row>
    <v-row class="sc-title mt-7">
      <v-col md="8" sm="6">
        <div>
          <h1>Rep = Billy Krohne</h1>
        </div>
      </v-col>
    </v-row>

    <v-data-table :headers="headers" :items="items">
      <template v-slot:item.foreman_phone="{ item }">
        <a :href="'tel:' + item.foreman_phone">{{ item.foreman_phone }}</a>
      </template>
      <template v-slot:item.telephone="{ item }">
        <a :href="'tel:' + item.telephone">{{ item.telephone }}</a>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        { text: "Customer", value: "customer" },
        { text: "City", value: "city" },
        { text: "Foreman", value: "foreman" },
        { text: "Foreman Phone", value: "foreman_phone" },
        { text: "Day/of", value: "day_of" },
        { text: "Telephone ", value: "telephone" },
        { text: "Minutes", value: "minutes" },
        { text: "Drive", value: "drive" },
      ],
      items: [
        {
          customer: "Bill Ramstein",
          city: "Overland Park",
          foreman: "Carlos",
          foreman_phone: "816-232-9564",
          day_of: "1/5",
          telephone: "913-222-2436",
          minutes: "12",
          drive: "",
        },
        {
          customer: "Mary Smith",
          city: "Raytown",
          foreman: "Kenny",
          foreman_phone: "816-232-9564",
          day_of: "2/2",
          telephone: "913-226-3345",
          minutes: "4",
          drive: "",
        },
      ],
    };
  },
};
</script>

<style></style>
